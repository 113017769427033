var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xl:w-56 lg:w-40 category-card-pack w-full min-max-h-full flex lg:flex-none md:align-left md:flex-row relative shadow",class:{
    'rounded-l-lg': Number(_vm.index) < 1 && !_vm.isSmallScreen,
    'rounded-t-lg': _vm.isSmallScreen && Number(_vm.index) >= 1,
    'rounded-b-lg': _vm.isSmallScreen,
    'rounded-r-lg':
      _vm.category.packRate.length - 1 === Number(_vm.index) && !_vm.isSmallScreen,
    'mb-3': !_vm.isSmallScreen,
  },style:(`background-color: ${
      _vm.category.packRate[_vm.index].featured ? '' : _vm.colors.colorSurface
    }`)},[(_vm.category.packRate[_vm.index].featured)?_c('div',{staticClass:"category-card-pack__featured"},[_c('F2gIcon',{attrs:{"value":"label","strokeWidth":3,"stroke":_vm.colors.colorPrimary,"fill":_vm.colors.colorPrimary,"width":30,"height":30}})],1):_vm._e(),(_vm.category.packRate[_vm.index].featured)?_c('div',{staticClass:"category-card-pack__background min-h-full w-full",class:{
      'rounded-l-lg': Number(_vm.index) < 1 && !_vm.isSmallScreen,
      'rounded-t-lg': _vm.isSmallScreen && Number(_vm.index) >= 1,
      'rounded-b-lg': _vm.isSmallScreen,
      'rounded-r-lg':
        _vm.category.packRate.length - 1 === Number(_vm.index) && !_vm.isSmallScreen,
    },style:(`--custom_color:${_vm.colors.colorSurface}`)}):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.category.errorDatesReserve)?_c('div',{key:"errorDates",staticClass:"text-normal text-red-700 max-w-lg w-full pt-4 px-4 pb-4 category-card-pack__error",staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticClass:"text-sm font-bold flex flex-row justify-center align-center errorText"},[_vm._v(" "+_vm._s(_vm.category.txtErrorDatesReserve)+" ")]),_c('div',{staticClass:"flex flex-row justify-end align-end md:h-40 h-12"},[_c('v-btn',{staticClass:"w-full border-2 rounded-config30 text-xs px-1",class:{ 'mt-2': _vm.showResume },style:({
            backgroundColor: _vm.colors.colorPrimary,
            color: _vm.colors.colorOnPrimary,
          }),on:{"click":function($event){return _vm.$emit('changeDates')}}},[_vm._v(" "+_vm._s(_vm.$t("modifyDates"))+" ")])],1)]):_c('div',{key:"content",staticClass:"max-h-full flex flex-col justify-between category-card-pack__content p-3"},[_c('div',[_c('div',{staticClass:"xl:text-title font-bold text-titleSmall flex flex-row-align-center align-center mb-4",class:{
            'text-sm': !_vm.reducePaymentCard,
            'text-xs': _vm.reducePaymentCard,
          },style:({ color: _vm.colors.colorOnSurface })},[_c('F2gIcon',{key:"info",staticClass:"mr-2 cursor-pointer",attrs:{"value":"info","strokeWidth":3,"width":16,"height":16,"solid":"","file":"icons_solid.svg","fill":_vm.$CONSTANTS.COLOUR.BLUE_1_F2G},on:{"click":_vm.showDescription}}),_c('span',[_vm._v(_vm._s(_vm.category.packRate[_vm.index].name))])],1),_c('div',{staticClass:"category-card-pack__content__data",class:{
            'category-card-pack__content__data--shown': _vm.showDetails,
            'category-card-pack__content__data--hidded ': !_vm.showDetails,
          }},[_c('div',{staticClass:"category-card-pack__content__data__items flex flex-col justify-between",class:{
              'category-card-pack__content__data__items--shown': _vm.showDetails,
              'category-card-pack__content__data__items--hidded ': !_vm.showDetails,
            },attrs:{"id":`contentDataItems${_vm.index}`}},[_c('div',{staticClass:"flex-col mb-2 text-xs xl:text-sm",style:({ color: _vm.colors.colorOnSurface })},[_c('div',{staticClass:"flex flex-row-align-center"},[_c('F2gIcon',{staticClass:"mr-2",attrs:{"value":"checked-circle","strokeWidth":3,"stroke":_vm.colors.colorPrimary,"width":16,"height":16}}),_c('span',[_vm._v(_vm._s(_vm.category.packRate[_vm.index].kmIncluded > -1 ? `${_vm.category.packRate[_vm.index].kmIncluded} ${_vm.$t( _vm.category.packRate[_vm.index].kmIncluded === 1 ? "kmIncluded" : "kmIncludeds" )}` : _vm.$t("unlimitedKm")))])],1)]),_c('div',{staticClass:"flex-col mb-2 text-xs xl:text-sm",style:({ color: _vm.colors.colorOnSurface })},[_c('div',{staticClass:"flex flex-row-align-center"},[_c('F2gIcon',{staticClass:"mr-2",attrs:{"value":"checked-circle","strokeWidth":3,"stroke":_vm.colors.colorPrimary,"width":16,"height":16}}),_c('span',[_vm._v(_vm._s(_vm.category.packRate[_vm.index].fuelText[_vm.language] ? _vm.category.packRate[_vm.index].fuelText[_vm.language] : _vm.category.packRate[_vm.index].fuelText.en))])],1)]),(_vm.category.packRate[_vm.index].freeCancellation)?_c('div',{staticClass:"flex-col mb-2 text-xs xl:text-sm",style:({ color: _vm.colors.colorOnSurface })},[_c('div',{staticClass:"flex flex-row-align-center"},[_c('F2gIcon',{staticClass:"mr-2",attrs:{"value":"checked-circle","strokeWidth":3,"stroke":_vm.colors.colorPrimary,"width":16,"height":16}}),_c('span',[_vm._v(_vm._s(_vm.$t("freeCancellation")))])],1)]):_vm._e(),(_vm.category.packRate[_vm.index].franchise)?_c('div',{staticClass:"flex-col mb-2 text-xs xl:text-sm",style:({ color: _vm.colors.colorOnSurface })},[_c('div',{staticClass:"flex flex-row-align-center"},[_c('F2gIcon',{staticClass:"mr-2",attrs:{"value":"checked-circle","strokeWidth":3,"stroke":_vm.colors.colorPrimary,"width":16,"height":16}}),_c('span',[_vm._v(_vm._s(_vm.category.packRate[_vm.index].franchise))])],1)]):_vm._e(),_vm._l((_vm.category.packRate[_vm.index]
                .characteristic),function(characteristic,index){return _c('div',{key:index,staticClass:"flex-col mb-2 text-xs xl:text-sm",style:({ color: _vm.colors.colorOnSurface })},[_c('div',{staticClass:"flex flex-row-align-center"},[_c('F2gIcon',{staticClass:"mr-2",attrs:{"value":"checked-circle","strokeWidth":3,"stroke":_vm.colors.colorPrimary,"width":16,"height":16}}),_c('span',[_vm._v(_vm._s(characteristic))])],1)])})],2)])]),_c('div',{staticClass:"category-card-pack__content__details"},[_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[(_vm.showDetails)?_c('div',{key:"show",staticClass:"flex flex-row align-center cursor-pointer w-full min-h-8",attrs:{"align-center":""},on:{"click":_vm.toggleShowDetails}},[_c('span',{staticClass:"mr-1",class:{
                'text-xs': !_vm.reducePaymentCard,
                'text-xxs': _vm.reducePaymentCard,
              }},[_vm._v(_vm._s(_vm.$t("hideDetails")))]),_c('F2gIcon',{attrs:{"value":"arrow-up","strokeWidth":3,"stroke":_vm.colors.colorPrimary,"width":26,"height":26}})],1):_c('div',{key:"hide",staticClass:"flex flex-row align-center cursor-pointer w-full min-h-8",attrs:{"align-center":""},on:{"click":_vm.toggleShowDetails}},[_c('span',{staticClass:"mr-1",class:{
                'text-xs': !_vm.reducePaymentCard,
                'text-xxs': _vm.reducePaymentCard,
              }},[_vm._v(_vm._s(_vm.$t("seeDetails")))]),_c('F2gIcon',{attrs:{"value":"arrow-down","strokeWidth":3,"stroke":_vm.colors.colorPrimary,"width":26,"height":26}})],1)])],1),_c('div',{staticClass:"xl:mt-2",class:{
          'mt-3': !_vm.reducePaymentCard,
          'mt-1': _vm.reducePaymentCard,
        }},[_c('div',{staticClass:"category-card-pack__content__price"},[_c('div',{staticClass:"font-bold",style:({ color: _vm.colors.colorPrimary })},[_c('span',{staticClass:"xl:text-2xl",class:{
                'text-xl': !_vm.reducePaymentCard,
                'text-lg': _vm.reducePaymentCard,
              }},[_vm._v(_vm._s(_vm.BillingUtil.formatBillingValue( _vm.getPricePerTime, _vm.category.symbol )))]),_c('span',{staticClass:"text-md"},[_vm._v(" "+_vm._s(_vm.isDays ? `/${_vm.$t("common.day")}` : `/${_vm.$t("common.hour")}`))])]),_c('div',{staticClass:"category-card-pack__content__price__day font-bold",class:{
              'text-xs': !_vm.reducePaymentCard,
              'text-xxs': _vm.reducePaymentCard,
            },style:({ color: _vm.colors.colorPrimary })},[_vm._v(" ( "),_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.BillingUtil.formatBillingValue(_vm.getPrice, _vm.category.symbol)))]),_c('span',[_vm._v(_vm._s(_vm.getTime))]),_vm._v(" ) ")])]),(!_vm.category.notAvailable)?_c('v-btn',{staticClass:"w-full border-2 rounded-config30",class:{
            'mt-3': !_vm.reducePaymentCard,
            'mt-1 text-xs': _vm.reducePaymentCard,
          },style:({
            backgroundColor: _vm.colors.colorPrimary,
            color: _vm.colors.colorOnPrimary,
          }),on:{"click":_vm.selectPackRate}},[_vm._v(" "+_vm._s(_vm.$t("select"))+" ")]):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }