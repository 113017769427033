<template>
  <div
    class="xl:w-56 lg:w-40 category-card-pack w-full min-max-h-full flex lg:flex-none md:align-left md:flex-row relative shadow"
    :class="{
      'rounded-l-lg': Number(index) < 1 && !isSmallScreen,
      'rounded-t-lg': isSmallScreen && Number(index) >= 1,
      'rounded-b-lg': isSmallScreen,
      'rounded-r-lg':
        category.packRate.length - 1 === Number(index) && !isSmallScreen,
      'mb-3': !isSmallScreen,
    }"
    :style="
      `background-color: ${
        category.packRate[index].featured ? '' : colors.colorSurface
      }`
    "
  >
    <div
      class="category-card-pack__featured"
      v-if="category.packRate[index].featured"
    >
      <F2gIcon
        value="label"
        :strokeWidth="3"
        :stroke="colors.colorPrimary"
        :fill="colors.colorPrimary"
        :width="30"
        :height="30"
      />
    </div>

    <div
      v-if="category.packRate[index].featured"
      class="category-card-pack__background min-h-full w-full"
      :class="{
        'rounded-l-lg': Number(index) < 1 && !isSmallScreen,
        'rounded-t-lg': isSmallScreen && Number(index) >= 1,
        'rounded-b-lg': isSmallScreen,
        'rounded-r-lg':
          category.packRate.length - 1 === Number(index) && !isSmallScreen,
      }"
      :style="`--custom_color:${colors.colorSurface}`"
    ></div>
    <transition name="fade" mode="out-in">
      <div
        v-if="category.errorDatesReserve"
        class="text-normal text-red-700 max-w-lg w-full pt-4 px-4 pb-4 category-card-pack__error"
        style="display: flex; flex-direction: column"
        key="errorDates"
      >
        <div
          class="text-sm font-bold flex flex-row justify-center align-center errorText"
        >
          {{ category.txtErrorDatesReserve }}
        </div>
        <div class="flex flex-row justify-end align-end md:h-40 h-12">
          <v-btn
            class="w-full border-2 rounded-config30 text-xs px-1"
            :class="{ 'mt-2': showResume }"
            :style="{
              backgroundColor: colors.colorPrimary,
              color: colors.colorOnPrimary,
            }"
            @click="$emit('changeDates')"
          >
            {{ $t("modifyDates") }}
          </v-btn>
        </div>
      </div>
      <!-- content -->
      <div
        class="max-h-full flex flex-col justify-between category-card-pack__content p-3"
        v-else
        key="content"
      >
        <div>
          <div
            class="xl:text-title font-bold text-titleSmall flex flex-row-align-center align-center mb-4"
            :class="{
              'text-sm': !reducePaymentCard,
              'text-xs': reducePaymentCard,
            }"
            :style="{ color: colors.colorOnSurface }"
          >
            <F2gIcon
              key="info"
              class="mr-2 cursor-pointer"
              value="info"
              :strokeWidth="3"
              :width="16"
              :height="16"
              solid
              file="icons_solid.svg"
              :fill="$CONSTANTS.COLOUR.BLUE_1_F2G"
              @click="showDescription"
            >
            </F2gIcon>
            <span>{{ category.packRate[index].name }}</span>
          </div>
          <div
            class="category-card-pack__content__data"
            :class="{
              'category-card-pack__content__data--shown': showDetails,
              'category-card-pack__content__data--hidded ': !showDetails,
            }"
          >
            <div
              class="category-card-pack__content__data__items flex flex-col justify-between"
              :class="{
                'category-card-pack__content__data__items--shown': showDetails,
                'category-card-pack__content__data__items--hidded ': !showDetails,
              }"
              :id="`contentDataItems${index}`"
            >
              <!-- included km -->
              <div
                class="flex-col mb-2 text-xs xl:text-sm"
                :style="{ color: colors.colorOnSurface }"
              >
                <div class="flex flex-row-align-center">
                  <F2gIcon
                    class="mr-2"
                    value="checked-circle"
                    :strokeWidth="3"
                    :stroke="colors.colorPrimary"
                    :width="16"
                    :height="16"
                  />
                  <span>{{
                    category.packRate[index].kmIncluded > -1
                      ? `${category.packRate[index].kmIncluded} ${$t(
                          category.packRate[index].kmIncluded === 1
                            ? "kmIncluded"
                            : "kmIncludeds"
                        )}`
                      : $t("unlimitedKm")
                  }}</span>
                </div>
              </div>
              <!-- fuelText -->
              <div
                class="flex-col mb-2 text-xs xl:text-sm"
                :style="{ color: colors.colorOnSurface }"
              >
                <div class="flex flex-row-align-center">
                  <F2gIcon
                    class="mr-2"
                    value="checked-circle"
                    :strokeWidth="3"
                    :stroke="colors.colorPrimary"
                    :width="16"
                    :height="16"
                  />
                  <span>{{
                    category.packRate[index].fuelText[language]
                      ? category.packRate[index].fuelText[language]
                      : category.packRate[index].fuelText.en
                  }}</span>
                </div>
              </div>
              <!-- freeCancellation -->
              <div
                v-if="category.packRate[index].freeCancellation"
                class="flex-col mb-2 text-xs xl:text-sm"
                :style="{ color: colors.colorOnSurface }"
              >
                <div class="flex flex-row-align-center">
                  <F2gIcon
                    class="mr-2"
                    value="checked-circle"
                    :strokeWidth="3"
                    :stroke="colors.colorPrimary"
                    :width="16"
                    :height="16"
                  />
                  <span>{{ $t("freeCancellation") }}</span>
                </div>
              </div>
              <!-- franchise -->
              <div
                class="flex-col mb-2 text-xs xl:text-sm"
                :style="{ color: colors.colorOnSurface }"
                v-if="category.packRate[index].franchise"
              >
                <div class="flex flex-row-align-center">
                  <F2gIcon
                    class="mr-2"
                    value="checked-circle"
                    :strokeWidth="3"
                    :stroke="colors.colorPrimary"
                    :width="16"
                    :height="16"
                  />
                  <span>{{ category.packRate[index].franchise }}</span>
                </div>
              </div>
              <!-- characteristic -->
              <div
                class="flex-col mb-2 text-xs xl:text-sm"
                :style="{ color: colors.colorOnSurface }"
                v-for="(characteristic, index) in category.packRate[index]
                  .characteristic"
                :key="index"
              >
                <div class="flex flex-row-align-center">
                  <F2gIcon
                    class="mr-2"
                    value="checked-circle"
                    :strokeWidth="3"
                    :stroke="colors.colorPrimary"
                    :width="16"
                    :height="16"
                  />
                  <span>{{ characteristic }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="category-card-pack__content__details">
          <transition name="slide-fade" mode="out-in">
            <div
              class="flex flex-row align-center cursor-pointer w-full min-h-8"
              v-if="showDetails"
              align-center
              key="show"
              @click="toggleShowDetails"
            >
              <span
                class="mr-1"
                :class="{
                  'text-xs': !reducePaymentCard,
                  'text-xxs': reducePaymentCard,
                }"
                >{{ $t("hideDetails") }}</span
              >
              <F2gIcon
                value="arrow-up"
                :strokeWidth="3"
                :stroke="colors.colorPrimary"
                :width="26"
                :height="26"
              />
            </div>
            <div
              class="flex flex-row align-center cursor-pointer w-full min-h-8"
              v-else
              align-center
              key="hide"
              @click="toggleShowDetails"
            >
              <span
                class="mr-1"
                :class="{
                  'text-xs': !reducePaymentCard,
                  'text-xxs': reducePaymentCard,
                }"
                >{{ $t("seeDetails") }}</span
              >
              <F2gIcon
                value="arrow-down"
                :strokeWidth="3"
                :stroke="colors.colorPrimary"
                :width="26"
                :height="26"
              />
            </div>
          </transition>
        </div>
        <!-- price time -->
        <div
          class="xl:mt-2"
          :class="{
            'mt-3': !reducePaymentCard,
            'mt-1': reducePaymentCard,
          }"
        >
          <div class="category-card-pack__content__price">
            <div :style="{ color: colors.colorPrimary }" class="font-bold">
              <span
                class="xl:text-2xl"
                :class="{
                  'text-xl': !reducePaymentCard,
                  'text-lg': reducePaymentCard,
                }"
                >{{
                  BillingUtil.formatBillingValue(
                    getPricePerTime,
                    category.symbol
                  )
                }}</span
              ><span class="text-md">
                {{
                  isDays ? `/${$t("common.day")}` : `/${$t("common.hour")}`
                }}</span
              >
            </div>
            <div
              class="category-card-pack__content__price__day font-bold"
              :class="{
                'text-xs': !reducePaymentCard,
                'text-xxs': reducePaymentCard,
              }"
              :style="{ color: colors.colorPrimary }"
            >
              (
              <span class="mr-2">{{
                BillingUtil.formatBillingValue(getPrice, category.symbol)
              }}</span>
              <span>{{ getTime }}</span> )
            </div>
          </div>
          <v-btn
            v-if="!category.notAvailable"
            class="w-full border-2 rounded-config30"
            :class="{
              'mt-3': !reducePaymentCard,
              'mt-1 text-xs': reducePaymentCard,
            }"
            :style="{
              backgroundColor: colors.colorPrimary,
              color: colors.colorOnPrimary,
            }"
            @click="selectPackRate"
          >
            {{ $t("select") }}
          </v-btn>
        </div>
      </div>
      <!-- end content -->
    </transition>
  </div>
</template>

<script>
import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";
import { mapGetters } from "vuex";

export default {
  name: "CategoryCardPack",
  props: {
    category: { type: Object, required: true },
    reducePaymentCard: { type: Boolean, required: false, default: false },
    showResume: { type: Boolean, required: false, default: false },
    index: { type: Number, required: true },
  },
  data() {
    return {
      BillingUtil,
      showDetails: false,
    };
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
      language: "configuration/getUserLanguage",
    }),
    isSmallScreen() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      );
    },
    isDays() {
      return this.category.packRate[this.index].timeType === "day";
    },
    getTime() {
      const pack = this.category.packRate[this.index];
      let time = `${pack.time} `;

      if (this.isDays) {
        time += this.$t(pack.time === 1 ? "common.day" : "common.days");
      } else {
        time += this.$t(pack.time === 1 ? "common.hour" : "common.hours");
      }

      return time;
    },
    getPricePerTime() {
      let price;
      if (
        this.category.packRate[this.index].deferredDiscount &&
        this.category.packRate[this.index].deferredPayment &&
        this.category.packRate[this.index].pricePerTimeDeferred
      ) {
        price = this.category.packRate[this.index].pricePerTimeDeferred;
      } else if (this.category.packRate[this.index].pricePerTime) {
        price = this.category.packRate[this.index].pricePerTime;
      } else {
        price = 0;
      }
      return price;
    },
    getPrice() {
      let price;
      if (
        this.category.packRate[this.index].deferredPayment &&
        this.category.packRate[this.index].deferredDiscount &&
        this.category.packRate[this.index].totalPriceDeferredDiscount
      ) {
        price = this.category.packRate[this.index].totalPriceDeferredDiscount;
      } else if (this.category.packRate[this.index].price) {
        price = this.category.packRate[this.index].price;
      } else {
        price = 0;
      }
      return price;
    },
  },
  mounted() {
    if (window.innerWidth <= 960) {
      setTimeout(
        () => (
          (document.getElementById(
            `contentDataItems${this.index}`
          ).style.display = "none"),
          500
        )
      );
    }
  },
  methods: {
    selectPackRate() {
      this.$emit("packSelected", this.category.packRate[this.index]);
    },
    showDescription() {
      this.$emit("showDescription", this.index);
    },
    toggleShowDetails() {
      this.showDetails = !this.showDetails;
      if (this.showDetails) {
        document.getElementById(`contentDataItems${this.index}`).style.display =
          "block";
      } else {
        setTimeout(
          () =>
            (document.getElementById(
              `contentDataItems${this.index}`
            ).style.display = "none"),
          1500
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.category-card-pack {
  position: relative;
  z-index: 20;

  &__background {
    position: absolute;
    z-index: 24;
    background-color: rgba(var(--custom_color), 0.1);
  }

  &__content {
    position: relative;
    z-index: 30;

    &__price {
      display: flex;
      flex-direction: column;
    }

    &__details {
      display: none;
    }

    @media (max-width: 960px) {
      &__price {
        flex-direction: row;
        align-content: center;

        &__day {
          flex-direction: row;
          justify-content: center;
          align-content: center;
          padding-top: 5px;
          padding-left: 5px;
        }
      }

      &__details {
        display: block;
      }

      &__data {
        overflow-y: hidden;
        &--shown {
          max-height: 8000px;
          transition: max-height 1s ease-in-out;
        }

        &--hidded {
          max-height: 0px !important;
          transition: max-height 1s ease-in-out;
        }

        &__items {
          &--shown {
            opacity: 1;
            transition: opacity 1s ease-in-out;
          }

          &--hidded {
            opacity: 0;
            transition: opacity 1s ease-in-out;
          }
        }
      }
    }
  }

  &__error {
    position: relative;
    z-index: 30;
  }

  &__featured {
    position: absolute;
    z-index: 50;
    top: 0;
    right: 0;
  }
}
</style>
